import baa from './imgs/clients/baa.png';
import brandt from './imgs/clients/brandt.png';
import cocacola from './imgs/clients/cocacola.png';
import CookieConsent, { getCookieConsentValue, Cookies, } from "react-cookie-consent";
import dalkia from './imgs/clients/dalkia.png';
import digiplex from './imgs/clients/digiplex.png';
import ecom90logo from './imgs/ecom90logo.png';
import environ from './imgs/clients/environ.png';
import erm360 from './imgs/clients/erm360.png';
import exor from './imgs/clients/ex-or.png';
import gent from './imgs/clients/gent.png';
import honeywell from './imgs/clients/honeywell.png';
import ReactGA from 'react-ga4';
import slide01 from './imgs/slide01.jpg';
import slide02 from './imgs/slide02.jpg';
import slide03 from './imgs/slide03.jpg';
import slide04 from './imgs/slide04.jpg';
import tbias from './imgs/clients/tbias.png';
import trend from './imgs/clients/trend.png';
import wdr from './imgs/clients/wdr.png';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { useEffect } from 'react';

const handleAcceptCookie = () => {
  ReactGA.initialize('G-P2SCJH8GN3');
  ReactGA.send({ hitType: "pageview", page: "/" });
}

const handleDeclineCookie = () => {
  //remove google analytics cookies
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
};

function App() {

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      ReactGA.send({ hitType: "pageview", page: "/" });
    }
  }, [])

  return (<>
    <Container fluid>
      <Row className='mb-4'>
        <Col className='p-0'>
          <Carousel pause={false}>

            <Carousel.Item>
              <img className='carousel-img' src={slide01} alt='First slide' />
              <Carousel.Caption bsPrefix='carousel-caption-ct'>
                <img className='mb-3' style={{ maxWidth: '60%' }} src={ecom90logo} alt='eCom90 logo' />
                <div className='carousel-h2 carousel-dark'>Adding value through IT since 2001</div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className='carousel-img' src={slide02} alt='Second slide' />
              <Carousel.Caption bsPrefix='carousel-caption-ct'>
                <div className='carousel-h1'>Technology advisory services</div>
                <div className='carousel-h2'>Helping you to select the right technologies for your business.</div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className='carousel-img' src={slide03} alt='Third slide' />
              <Carousel.Caption bsPrefix='carousel-caption-ct'>
                <div className='carousel-h1'>Implementation strategy</div>
                <div className='carousel-h2'>Investment and implementation roadmaps. Resources, software, tools, timelines.</div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className='carousel-img' src={slide04} alt='Fourth slide' />
              <Carousel.Caption bsPrefix='carousel-caption-ct'>
                <div className='carousel-h1'>IT Resource Management</div>
                <div className='carousel-h2'>of your internal and/or outsourced resources.</div>
              </Carousel.Caption>
            </Carousel.Item>

          </Carousel>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col className='text-center'>
          <a href='mailto:enquiry@ecom90.com'>enquiry@ecom90.com</a>
          <span style={{ marginLeft: '0.5rem' }}>Tel: +44 (0)330 124 2396</span><span className='fst-italic' style={{ marginLeft: '0.5rem' }}>(Out of Hours: +44 (0)781 256 4122, +44 (0)781 252 7094)</span>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='text-center'>
          <h3>
            Clients
          </h3>
          <div>
            <img className='carousel-client-logo' src={baa} alt='BAA logo' />
            <img className='carousel-client-logo' src={brandt} alt='Brandt logo' />
            <img className='carousel-client-logo' src={cocacola} alt='CocaCola logo' />
            <img className='carousel-client-logo' src={dalkia} alt='Dalkia logo' />
            <img className='carousel-client-logo' src={digiplex} alt='DigiPlex logo' />
            <img className='carousel-client-logo' src={environ} alt='Environ logo' />
            <img className='carousel-client-logo' src={exor} alt='Ex-Or logo' />
            <img className='carousel-client-logo' src={gent} alt='Gent logo' />
            <img className='carousel-client-logo' src={honeywell} alt='Honeywell logo' />
            <img className='carousel-client-logo' src={tbias} alt='TBIAS logo' />
            <img className='carousel-client-logo' src={trend} alt='Trend logo' />
            <img className='carousel-client-logo' src={wdr} alt='WDR logo' />
          </div>
        </Col>
      </Row>
      <Row className='m-4'>
        <Col>
          <p className='text-center'>
            <span className='fw-bold'>eCom90 Limited</span>, Gilrudding, Winterborne Muston, Blandford Forum, DORSET, DT119BU
          </p>
        </Col>
      </Row>
      <Row className='m-4' style={{paddingBottom: '6rem'}}>
        <Col>
          <p className='text-center'>
            <i>The development team behind the </i><a href='https://www.erm360.co.uk/' target='_blank'><img style={{ margin: '0 0.5rem', cursor: 'pointer' }} src={erm360} alt='erm360 logo' /></a><i>Software as a Service.</i>
          </p>
        </Col>
      </Row>
    </Container>
    <CookieConsent
      // style={{ paddingTop: '-2rem' }}
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
    >
      <span className='fw-bold m-2'>About your privacy:</span>This site uses cookies from Google to analyze traffic.
    </CookieConsent>
  </>);

}

export default App;
